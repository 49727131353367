.sign-up-confirmation {
  height: 100vh;
  background-image: url("./duotone.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-confirmation .container {
  width: 600px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.sign-up-confirmation .container .corps {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.sign-up-confirmation .container h1 {
  text-align: center;
}

.sign-up-confirmation .bold {
  font-weight: bold;
}

.sign-up-confirmation-logo {
  margin-bottom: 40px;
  text-align: center;
}

.sign-up-confirmation-logo img {
  width: 300px;
}

.sign-up-confirmation .already-member {
  padding-bottom: 20px;
}

@media screen and (max-width: 900px) {
  .sign-up-confirmation .container {
    width: 300px;
  }

  .sign-up-confirmation-logo img {
    width: 200px;
  }
}
