.days {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.days-block {
    margin-bottom: 30px;
}

.day {
    display: flex;
}

.days .title-day {
    font-family: "Bebas Neue";
    text-transform: uppercase;
    margin-right: 20px;
    font-size: 20px;
}

.days .seance-in-day {
    text-align: center;
    padding: 20px;
    background-color: #F2F2F2;
    margin: 0 20px;
    width: 200px;
    border: 1px solid grey;
    border-radius: 20px;
}