.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  place-items: center;
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
  .ais-Hits-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .ais-Hits-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1601px) {
  .ais-Hits-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

.exercise-choice-list .ais-Hits-list {
  grid-template-columns: repeat(1, 1fr);
}

.ais-SearchBox-form {
  background-color: #fff;
  display: flex;
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 1.25rem;
  position: relative;
  width: 100%;
}

.ais-SearchBox-input {
  font-family: "Mulish";
  appearance: none;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  box-shadow: rgb(119 122 175 / 30%) 0 1px 4px 0 inset;
  caret-color: #5a5e9a;
  color: #23263b;
  flex: 1 1;
  max-width: 100%;
  padding-left: 2.5rem;
}

.ais-SearchBox-form::before {
  background: transparent
    url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235a5e9a%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E)
    repeat scroll 0% 0%;
  content: "";
  height: 1rem;
  left: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 1rem;
}

.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-reset {
  display: none;
}
