.athlete-home {
  margin: 0 auto;
  max-width: 350px;
}

.body {
  color: #fff;
}

.athlete-home .white-title {
  text-align: left;
}

.athlete-home .button {
  margin-bottom: 20px;
}

.athlete-home .button:hover {
  background-color: var(--blue);
}
