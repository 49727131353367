@font-face {
  font-family: "Museo";
  src: local("Museo"),
    url(../../fonts/Museo/Museo700-Regular.otf) format("truetype");
}

body {
  margin: 0;
  background-color: #f1f1f1;
}

body::-webkit-scrollbar {
  width: 0.25em;
}

body::-webkit-scrollbar-track {
  background-color: #71e0f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #407c97;
}

h3 {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  text-transform: uppercase;
  font-weight: 150;
}

button {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: medium;
  color: #ffffff;
  transition: 0.5s all ease-out;
}

/* tr:hover {
  cursor: pointer;
  color: #407c97;
  opacity: 0.8;
} */

iframe {
  height: -webkit-fill-available;
  border-width: 0;
  border-style: inset;
  border-color: initial;
  border-image: initial;
}

.fa-1x:hover {
  cursor: pointer;
  color: #407c97;
  opacity: 0.8;
}

.fa-2x:hover {
  cursor: pointer;
  color: #407c97;
  opacity: 0.8;
}

a:hover {
  cursor: pointer;

  opacity: 0.8;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #71e0f1 !important;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-offset-0,
.col-sm-offset-1,
.col-sm-offset-2,
.col-sm-offset-3,
.col-sm-offset-4,
.col-sm-offset-5,
.col-sm-offset-6,
.col-sm-offset-7,
.col-sm-offset-8,
.col-sm-offset-9,
.col-sm-offset-10,
.col-sm-offset-11,
.col-sm-offset-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-offset-0,
.col-md-offset-1,
.col-md-offset-2,
.col-md-offset-3,
.col-md-offset-4,
.col-md-offset-5,
.col-md-offset-6,
.col-md-offset-7,
.col-md-offset-8,
.col-md-offset-9,
.col-md-offset-10,
.col-md-offset-11,
.col-md-offset-12,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-offset-0,
.col-lg-offset-1,
.col-lg-offset-2,
.col-lg-offset-3,
.col-lg-offset-4,
.col-lg-offset-5,
.col-lg-offset-6,
.col-lg-offset-7,
.col-lg-offset-8,
.col-lg-offset-9,
.col-lg-offset-10,
.col-lg-offset-11,
.col-lg-offset-12,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-offset-0,
.col-xl-offset-1,
.col-xl-offset-2,
.col-xl-offset-3,
.col-xl-offset-4,
.col-xl-offset-5,
.col-xl-offset-6,
.col-xl-offset-7,
.col-xl-offset-8,
.col-xl-offset-9,
.col-xl-offset-10,
.col-xl-offset-11,
.col-xl-offset-12 {
  padding: 15px;
  height: 100%;
}

div::-webkit-scrollbar {
  display: none;
}

.MuiBox-root-5 {
  padding: 0px;
}

a {
  color: var(--blue);
  text-decoration: none;
}

a:link {
  color: var(--blue);
  text-decoration: none;
  font-family: "Montserrat";
}

*,
::after,
::before {
  box-sizing: revert;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.Cell-cell-42 {
  cursor: pointer;
}

.Cell-cell-97 {
  cursor: pointer;
}
.Cell-cell-105 {
  cursor: pointer;
}

.Cell-cell-140 {
  cursor: pointer;
}

/* icone fontawesome */
.fa-lg {
  font-size: 19px;
}

.MuiCollapse-wrapperInner {
  width: 100%;
  display: flex;
  justify-content: center;
}
