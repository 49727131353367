.group {
  position: relative;
  margin-bottom: 40px;
}

input {
  font-family: "Montserrat";
  font-size: 20px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid var(--dark-blue);
}
input:focus {
  outline: none;
}
/* active state */
input:focus ~ .form-label,
input:valid ~ .form-label {
  top: -20px;
  font-size: 14px;
  color: var(--dark-blue);
}

.group .error {
  font-size: 12px;
  color: red;
  margin-top: 0.25rem;
  font-family: "Montserrat";
  max-width: 300px;
}

.group .no-error {
  font-size: 12px;
  color: red;
  margin-top: 0.25rem;
  font-family: "Montserrat";
  opacity: 0;
}

.group .error:before {
  content: "❌ ";
  font-size: 10px;
}

/* .form-label ======================================= */
.form-label {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  /* width: 300px; */
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--dark-blue);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: var(--light-blue);
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: var(--light-blue);
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: var(--light-blue);
  }
  to {
    width: 0;
    background: transparent;
  }
}
