:root {
  --dark-blue: #1a293f;
  --light-blue: #00679a;
  --blue: #004e68;

  --bg: #ffffff;
  --bg-accent: #484a4d;
  --text-color: #407c97;
  --nav-size: 60px;
  --border: 1px solid var(--dark-blue);
  --border-radius: 2px;
  --speed: 500ms;

  /* screens */
  --xs: 480px;
  --sm: 768px;
  --md: 1024px;
  --lg: 1200px;
}

.app-wrapper {
  background: var(--dark-blue);
  height: 100%;
  min-height: 100vh;
}

.title {
  font-family: "Bebas Neue";
  color: var(--light-blue);
  font-size: 28px;
}

p {
  font-family: "Montserrat";
  font-weight: 300;
}

.white-loading {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border: 2px solid;
  border-color: #fff;
  border-radius: 50%;
  border-top-color: rgb(255, 255, 255, 0);
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.checkbox {
  display: inline;
  width: auto;
}

.rmsc {
  font-family: "Montserrat";
}
.rmsc svg {
  height: 24px;
  width: 24px;
}
.rmsc input {
  width: 20px;
}

.ais-Pagination-list {
  display: flex;
  justify-content: center;
}

.ais-Pagination-link {
  margin: 0 10px;
}

/* SIGN UP REGISTRATION */
.signup {
  background-color: #fff;
}

.div-signup-registration {
  width: 300px;
  margin: auto;
  padding: 30px 0;
}

.div-signup-registration h1 {
  color: #1b252f;
}

.div-signup-registration span {
  color: #1b252f;
  font-family: "Montserrat";
}

.div-signup-registration p {
  color: #1b252f;
}

.div-signup-registration .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.div-signup-registration .logo-container img {
  width: 100%;
}

.div-signup-registration {
  height: 100vh;
  background-color: #fff;
}

.button-month-choice {
  width: 300px;
  height: 50px;
  background-color: #1b252f;
  font-family: "Bebas Neue";
  border: 0px;
  box-sizing: border-box;
  outline: unset;
  text-transform: uppercase;
}

.button-month-choice-selected {
  width:300px;
  height: 50px;
  background-color: var(--light-blue);
  font-family: "Bebas Neue";
  border: 0px;
  box-sizing: border-box;
  outline: unset;
  text-transform: uppercase;
}

.element.style {
  width: 100%;
}
