.sign-in {
  background-color: var(--dark-blue);
  display: flex;
  height: 100vh;
}

.sign-in-logo {
  background-image: url("./duotone.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-logo img {
  width: 50%;
}

.sign-in .form {
  width: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .sign-in {
    background-color: var(--dark-blue);
    display: flex;
    align-items: center;
  }
  .sign-in .form {
    width: 100%;

    height: 500px;
  }

  .sign-in-logo {
    display: none;
  }
}
