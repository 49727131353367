.button {
  background-color: var(--light-blue);
  width: 100%;
  height: 60px;

  border-radius: var(--border-radius);
  border: 0px;
  box-sizing: border-box;
  outline: unset;

  font-family: "Bebas Neue";
  font-size: 24px;
  text-transform: uppercase;
}

.button:hover {
  cursor: pointer;
  background-color: var(--dark-blue);
}

.button:disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
