.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-container:hover {
  cursor: pointer;
}

.end-report-div {
  width: 250px;
  margin: auto;
}
