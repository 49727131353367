/* :root {
  --bg: #ffffff;
  --bg-accent: #484a4d;
  --text-color: #407c97;
  --nav-size: 60px;
  --border: 1px solid var(--text-color);
  --border-radius: 8px;
  --speed: 500ms;
  --bleu-ciel: #71e0f1;
  --bleu-foncé: #407c97;
} */

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Top Navigation Bar */

/* <nav> */
.navbar-enjeux {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
}

/* <ul> */
.navbar-nav-enjeux {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--blue);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

svg {
  height: 40px;
  width: 40px;
}

/* Dropdown Menu */

.dropdown-enjeux {
  position: absolute;
  top: 58px;
  /* width: 300px; */
  transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
}

.menu-item-enjeux {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item-enjeux .icon-button {
  margin-right: 0.5rem;
}

.menu-item-enjeux .icon-button:hover {
  filter: none;
}

.menu-item-enjeux:hover {
  background-color: #f1f1f1;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.icon-button-badge {
  position: absolute;
  top: 35px;
  right: -2px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
