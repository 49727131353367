/* Example Styles for React Tags*/
div.ReactTags__tags {
  position: relative;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  -webkit-appearance: none;
  width: -webkit-fill-available;
  outline: unset;
  display: block;
  height: 40px;
  border-radius: 40px;
  border: 0px;
  box-shadow: 5px 5px 25px rgb(0 0 0 / 25%);
  background: #fff;
  padding: 0 25px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
  color: #b6b6b6;
  font-family: "Mulish";
}
/* Styles for selected tags */
div.ReactTags__selected {
  width: 250px;
}
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 14px;
  font-family: "Mulish";
  display: inline-block;
  padding: 5px;
  margin: 5px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}
/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}
