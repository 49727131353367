.silver-div {
  width: 300px;
  margin: auto;
  padding: 30px 0;
}

.silver-div h1 {
  text-align: center;
  color: #fff;
}

.silver-div li {
  color: #fff;
  font-family: "Montserrat";
  margin: 3px 0;
}

.silver-div .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.silver-div .logo-container img {
  width: 200px;
}

.silver-div p {
  color: #fff;
}

/* style.css */

.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  width: 90%;
  margin: 0 auto;
}

.card {
  flex: 0 0 auto;
  width: 80%;
  scroll-snap-align: start;
  scroll-padding: 1rem;
  margin-right: 1rem;
  box-sizing: border-box;
  border: 1px solid #ddd;
  /* ajoute une bordure */
  border-radius: 10px;
  /* arrondit les coins */
  padding: 1rem;
  /* ajoute un espace à l'intérieur de la carte */
  background-color: #fff;
  /* couleur de fond de la carte */
  position: relative;
  height: 450px;
  /* ajustez la hauteur des cartes selon vos besoins */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.discount {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: "Montserrat";
  display: flex;
  border-radius: 10px;
  background: #1a293f;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.title {
  margin-top: 30px;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.price {
  font-size: 2rem;
  margin-bottom: 0.1rem;
}

.price-details {
  font-size: 0.9rem;
  color: #000;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.price-details2 {
  margin-top: -5px;
  text-decoration: line-through;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.5rem;
}

.features {
  margin-bottom: 0.5rem;
  list-style-type: disc;
  padding-left: 1rem;
  color: "#000";
}

.features li {
  font-family: "Montserrat";
  font-size: 14px;
}

.promo-banner {
  background-color: #fff;
  /* couleur du fond du bandeau */
  color: #000;
  /* couleur du texte du bandeau */
  text-align: center;
  /* centre le texte */
  padding: 1rem;
  /* espace autour du texte */
}

