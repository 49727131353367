h1 {
  font-family: "Bebas Neue";
  text-transform: uppercase;
}

h2 {
  font-family: "Bebas Neue";
  text-transform: uppercase;
}

.white-title {
  color: #fff;
  text-align: center;
}
