.all-products {
  padding: 30px 0;
}

.products {
  display: flex;
  margin: 30px 50px;
}

.product {
  width: 33%;
  padding: 20px 0 40px 0;
  background-color: var(--light-blue);
  margin: 0 10px;
  border-radius: var(--border-radius);
}

.product .white-title {
  font-size: 40px;
}

.product .button-div {
  display: flex;
  justify-content: center;
}

.product .button {
  background-color: var(--blue);
  width: 200px;
}

.product .button:hover {
  background-color: var(--dark-blue);
}

.products .indication {
  margin-top: -20px;
  text-align: center;
  color: #fff;
}

.asterisk {
  font-size: 10px;
  color: #fff;
  text-align: center;
  padding: 0 20px;
  margin-top: 20px;
}

@media screen and (max-width: 900px) {
  .products {
    display: block;
    margin: 0 20px;
    padding-bottom: 20px;
  }

  .product {
    width: 100%;
    margin: 30px 0;
  }
}
