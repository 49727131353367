.group select {
  font-family: "Montserrat";
  font-size: 20px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 317px;
  border: none;
  border-bottom: 1px solid var(--dark-blue);
  outline: none;
}
