.chat {
  background-color: #fff;
  padding: 10px;
  height: 80vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar {
  width: 0.25rem;
}

.scroll::-webkit-scrollbar-track {
  background: #1e1e24;
}

.scroll::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.chat form {
  height: 5vh;
  position: fixed;
  bottom: 9px;
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
}

.chat form button {
  width: 20%;
  background-color: #71e0f1;
}

.chat input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: #fff;
  color: #000;
  outline: none;
  border: none;
}

.chat button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

.chat button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chat ul,
li {
  text-align: left;
}

.chat p {
  max-width: 500px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.chat .date {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-style: italic;
  font-size: 12px;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #004e68;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

.chat .message-date {
  font-size: 12px;
}
