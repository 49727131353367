.payment {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment .cgu label {
  color: #fff;
  margin-top: 3px;
}

.payment .cgu a {
  color: #fff;
}

.payment .button:hover {
  background-color: var(--blue);
}

.payment .white-title {
  max-width: 350px;
}
