.sign-up {
  background-color: var(--dark-blue);
  display: flex;
}

.logo {
  background-image: url("./duotone.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50%;
}

.logo .logo-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 50%;
}

.sign-up .form {
  padding: 50px 0;
  width: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cgu {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-family: "Montserrat";
  max-width: 300px;
}

.cgu:hover {
  cursor: pointer;
}

.cgu input {
  height: 20px;
  width: 30px;
  left: 4px;
  margin: 0;
  margin-right: 5px;
}

.cgu .cgu-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cgu input {
  cursor: pointer;
}

.cgu label {
  font-size: 12px;
  color: var(--dark-blue);
}

.cgu a {
  font-weight: bold;
}

.already-member {
  margin-top: 10px;
}
.already-member a {
  font-family: "Montserrat";
  font-size: 12px;
}

@media screen and (max-width: 900px) {
  .sign-up {
    display: block;
  }

  .sign-up .form {
    width: 100%;
    height: 100%;
  }

  .logo {
    display: none;
  }
}
